import { getEnvStr } from './index';
import { getCookie, delCookie } from './cookie';
import { ACCESS_TOKEN, TOKEN_SOURCE, VISITOR_TOKEN } from './keys';
import { checkTokenExpired } from '../service/token';
import { getVisitorToken } from '../service/tokenless';
import { autherAndLogin } from '../service/authentication';
import { checkLoginStatus } from '../service/login';
import { getToken as getAppToken } from '@gaodun.com/rainbow';
import { jsbridgeRun } from './jsbridge';

/**
 * 获取本地token
 * @returns token
 */
export const getLocalAccessToken = (isPro?: boolean): Promise<string> => {
    return new Promise((resolve, reject) => {
        let opts = {
            new: () => {
                getAppToken()
                    .then(res => {
                        const { accessToken } = res;
                        // app返回已含有Basic，h5自身又在别处添加，因此直接剔除
                        resolve(accessToken.replace('Basic ', ''));
                    })
                    .catch(e => {
                        resolve('');
                    });
            },
            web: () => {
                let tokenKey = isPro ? ACCESS_TOKEN : getEnvStr(ACCESS_TOKEN);
                const accessToken = getCookie(tokenKey);
                resolve(accessToken);
            },
        };
        let source = sessionStorage.getItem(TOKEN_SOURCE);
        if (source == 'cookie') {
            opts.web();
        } else {
            jsbridgeRun(opts);
        }
    });
};

/**
 * 获取token
 * @param visitor 是否为获取游客token
 * @returns token string
 */
const getToken = (visitor?: boolean): Promise<string> =>
    new Promise(async (resolve, reject) => {
        // 如果是获取游客token
        if (visitor) {
            const visitorToken = getCookie(getEnvStr(VISITOR_TOKEN));
            // 有token 且 未过期
            if (visitorToken && checkTokenExpired(visitorToken)) {
                resolve(visitorToken);
            } else {
                delCookie(getEnvStr(VISITOR_TOKEN));
                // 获取游客token
                let token = await getVisitorToken();
                resolve(token);
            }
        } else {
            let opts = {
                new: () => {
                    getAppToken()
                        .then(res => {
                            const { accessToken } = res;
                            // app返回已含有Basic，h5自身又在别处添加，因此直接剔除
                            resolve(accessToken.replace('Basic ', ''));
                        })
                        .catch(e => {
                            reject(e);
                        });
                },
                web: () => {
                    const accessToken = getCookie(getEnvStr(ACCESS_TOKEN));
                    // 检查token有效状态
                    checkLoginStatus(accessToken)
                        .then(token => {
                            // token有效
                            resolve(token);
                        })
                        .catch(error => {
                            // 微信环境
                            window['browser'].iswx
                                ? // 唤起授权登录
                                  autherAndLogin('forcibly')
                                      .then(token => {
                                          // 登录成功
                                          resolve(token);
                                      })
                                      .catch(e => {
                                          reject(e);
                                      })
                                : reject(error);
                        });
                },
            };
            let source = sessionStorage.getItem(TOKEN_SOURCE);
            if (source == 'cookie') {
                opts.web();
            } else {
                jsbridgeRun(opts);
            }
        }
    });
export default getToken;
