import sensors from 'sa-sdk-javascript';
import { getEnv } from '../utils';

/**
 * 神策初始化
 */
export const sensorsInit = () => {
    if (!getEnv()) {
        // 初始化神策统计
        sensors.init({
            name: 'sensors',
            server_url: 'https://gaodun.datasink.sensorsdata.cn/sa?project=jgxy_production&token=b9ea8de774df405c',
            is_track_single_page: true,
            // show_log: true,
            heatmap: {},
            show_log: true,
        });

        // 事件公共属性设置，在事件初始化后即刻调用，避免部分事件属性缺失
        sensors.registerPage({
            scrm_source: 'H5',
        });

        // 事件上报开关
        sensors.quick('autoTrack');
    }
};

/**
 * 登录后即刻调用，用于关联用户id
 * @param userId 高顿登录用户id
 */
export const sensorsLogin = (userId: string) => {
    if (!getEnv() && userId) {
        sensors.quick('isReady', function () {
            sensors.login(userId);
        });
    }
};

/**
 * 神策指定事件跟踪
 * @param fnName 事件名
 * @param params 事件属性
 */
export const sensorsTrack = (fnName: string, params: any = {}) => {
    if (!getEnv()) {
        sensors.quick('isReady', function () {
            sensors.track(fnName, {
                ...params,
                scrm_source: 'H5',
            });
        });
    }
};
