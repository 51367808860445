import qs from 'query-string';
import { getCookie, setCookie, delCookie } from '../utils/cookie';
import LStorage from '../utils/LStorage';
import { getEnvStr, json2formdata, getUrlParmas } from '../utils';
import {
    APPID,
    WX_USER_DATA,
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    USER_INFO,
    ACTIVITY_STOCK_COUNT,
    ACTIVITY_COUNT_EXPIRES,
    WX_TYPE,
} from '../utils/keys';
import {
    queryCountrycodeList,
    sendmessage,
    loginByCode,
    LoginByOtherType,
    loginAndBind,
    bindPhone,
    updataUserInfo,
    unbindingwx,
    servicePushWhenSendSms,
    switchWxStatus,
    sendMessageWithCaptcha,
} from './api';
import { getUserLoginInfo, setUserLoginInfo } from './userInfo';
import { getGDSSID, getWexinUserInfoByOpenid } from './tokenless';
import { checkToken } from './token';
import { getSourceInfo } from './sourceinfo';
import { sensorsLogin } from './sensors';
import { getCustomArgs } from './CustomArgsService';
import Captcha from '../utils/captcha';

/**
 * 获取用户微信授权信息
 * @param data 登录返回信息
 */
export const getCurrentWeappWxData = (userData: any, weappId) => {
    if (!userData) {
        return null;
    }

    let { UserWxExtend } = userData;
    // 微信信息存在且不为0
    if (UserWxExtend && UserWxExtend.length) {
        // 轮询用户多微信用户信息
        for (let i = 0; i < UserWxExtend.length; i++) {
            // 与当前小程序匹配
            if (UserWxExtend[i].WxAppId === weappId) {
                return UserWxExtend[i];
            }
        }
    }
    return null;
};

/**
 * 获取国家对应code列表
 */
export const getCountryCodeList = (): Promise<any[]> =>
    new Promise(async (resolve, reject) => {
        let res = await queryCountrycodeList({ hasMainland: true });
        if (!res.status) {
            let list = res.result.map(item => {
                return {
                    label: item.country_name,
                    value: item.country_code,
                };
            });
            resolve(list);
        } else {
            resolve([]);
        }
    });

// export const sendMessageV1 = (phone: string, areaCode?: string, where?: any): Promise<any> =>
//     new Promise(async (resolve, reject) => {
//         let GDSSID = await getGDSSID();
//         let PHPSESSID = getCookie('PHPSESSID') || GDSSID;
//         let phoneNum = areaCode === '86' ? phone : areaCode + phone;
//         let params: any = {
//             phone: phoneNum,
//             sessionId: PHPSESSID,
//             tpl: areaCode === '86' ? '' : 'SMS_171115471',
//         };
//         // 若是mcrm来源
//         let { url, query } = qs.parseUrl(window.location.href);
//         let searchUrl: any = qs.parse(window.location.search);
//         searchUrl = getUrlParmas(searchUrl);
//         if (searchUrl && searchUrl.sourceType === 'mcrm') {
//             params.tpl = areaCode === '86' ? 'SMS_225800021' : 'SMS_225381811';
//             params.label = '校园向上吧';
//         }
//         sendmessage(qs.stringify(params))
//             .then((data: any) => {
//                 if (!data.status) {
//                     searchUrl = !!where ? Object.assign(searchUrl, where) : searchUrl;
//                     let { sourceType, mcrmActivityTypeStr, x_field_1 } = searchUrl;
//                     if (!query['salesman']) {
//                         servicePushWhenSendSms({
//                             phone: phoneNum,
//                             platform: 'h5',
//                             xfield: x_field_1,
//                             sourceUrl: qs.stringifyUrl({
//                                 url,
//                                 query: Object.assign({}, getCustomArgs(), query),
//                             }),
//                             ...where,
//                             sourceType: !!sourceType ? sourceType : undefined,
//                             mcrmActivityTypeStr: !!mcrmActivityTypeStr ? mcrmActivityTypeStr : undefined,
//                         });
//                     }
//                     resolve('发送成功');
//                 } else {
//                     reject(data.info);
//                 }
//             })
//             .catch(e => {
//                 reject('发送失败，请稍后再试');
//             });
//     });

/**
 * 打开滑块验证
 * @returns 滑块验证结果
 */
export const captchaByTencent = (): Promise<any> =>
    new Promise((resolve, reject) => {
        try {
            (new Captcha({
                onCaptchaSuccess: (res: any) => {
                    resolve(res);
                }
            }))?.showCaptcha();
        } catch (error) {
            reject(error)
        }
    })

/**
 * 发送验证码
 * @param phone 手机号
 * @param areaCode 区号
 */
export const sendVerifyCode = (phone: string, areaCode?: string, where?: any): Promise<any> =>
    new Promise(async (resolve, reject) => {
        let GDSSID = await getGDSSID();
        let PHPSESSID = getCookie('PHPSESSID') || GDSSID;
        let phoneNum = areaCode === '86' ? phone : areaCode + phone;
        // 海外手机号进行滑块验证
        let _cap = areaCode !== '86' ? await captchaByTencent() : undefined;
        // 海外手机号滑块验证不通过
        if(areaCode !== '86' && (_cap?.ret !== 0 || !_cap?.ticket)) {
            return;
        }
        let params: any = {
            phone: phoneNum,
            sessionId: PHPSESSID,
            languageType: 'CH',
            tpl: areaCode === '86' ? '' : 'SMS_171115471',
            ticketRandStr: _cap?.randstr || '',
            ticket: _cap?.ticket || '',
        };

        // 若是mcrm来源
        let { url, query } = qs.parseUrl(window.location.href);
        let searchUrl: any = qs.parse(window.location.search);
        searchUrl = getUrlParmas(searchUrl);
        if (searchUrl && searchUrl.sourceType === 'mcrm') {
            params.tpl = areaCode === '86' ? 'SMS_225800021' : 'SMS_225381811';
            params.label = '校园向上吧';
        }
        (_cap && _cap?.errorCode === 1001 ? sendmessage(qs.stringify(params)) : sendMessageWithCaptcha(params))
            .then((data: any) => {
                if (!data.status) {
                    searchUrl = !!where ? Object.assign(searchUrl, where) : searchUrl;
                    let { sourceType, mcrmActivityTypeStr, x_field_1 } = searchUrl;
                    if (!query['salesman']) {
                        servicePushWhenSendSms({
                            phone: phoneNum,
                            platform: 'h5',
                            xfield: x_field_1,
                            sourceUrl: qs.stringifyUrl({
                                url,
                                query: Object.assign({}, getCustomArgs(), query),
                            }),
                            ...where,
                            sourceType: !!sourceType ? sourceType : undefined,
                            mcrmActivityTypeStr: !!mcrmActivityTypeStr ? mcrmActivityTypeStr : undefined,
                        });
                    }
                    resolve('发送成功');
                } else {
                    reject(data.info);
                }
            })
            .catch(e => {
                reject('发送失败，请稍后再试');
            });
    });

/**
 * 通过验证码登录
 * @param phone 手机号
 * @param code 验证码
 * @param name 姓名
 */
export const loginByVerify = (phone: string, code: string, name = '') =>
    new Promise(async (resolve, reject) => {
        let GDSSID = await getGDSSID();
        let PHPSESSID = getCookie('PHPSESSID') || GDSSID;
        let sourceInfo = await getSourceInfo();

        let params = {
            phone: phone.replace(/\s/g, ''),
            code: code.replace(/\s/g, ''),
            ucNickName: name,
            appid: APPID,
            GDSSID,
            PHPSESSIONID: PHPSESSID,
            channel: sourceInfo['channelId'] || '',
            project: sourceInfo['projectId'] || '',
        };
        let data = await loginByCode(qs.stringify(params));
        let { status, result, info } = data;
        // 登录成功
        if (!status) {
            setUserLoginInfo(data);
            sensorsLogin(result.UserData ? result.UserData.StudentId : '');
            checkWxStatus();
            resolve(data);
        } else {
            reject(info);
        }
    });

/**
 * 通过openid登录
 * @param openid
 * @param force true 为强制授权 不传或者 false 则为静默授权 若是强制授权将会获取userinfo更新到中台
 */
export const loginByWexinOpenid = (openid: string, force = false) =>
    new Promise(async (resolve, reject) => {
        let GDSSID = await getGDSSID();
        let PHPSESSID = getCookie('PHPSESSID') || GDSSID;
        let sourceInfo = await getSourceInfo();
        let wxUserInfo;

        // 若是强制授权, 静默授权无法获取用户信息
        if (!!force) {
            try {
                // 获取微信用户信息
                wxUserInfo = await getWexinUserInfoByOpenid(openid);
            } catch (error) {
                console.log(error);
            }
        }

        const params = {
            loginType: 'SCRM_AUTH_LOGIN',
            appid: APPID,
            GDSSID,
            source: 1,
            PHPSESSIONID: PHPSESSID,
            extendInfo: JSON.stringify({
                publicAppId: sourceInfo.weChatAppId,
                publicOpenId: wxUserInfo ? wxUserInfo.openId : openid,
                // 是否需要根据unionId登录 false:不需要 true:需要。默认true
                unionIdLogin: false,
                ...(wxUserInfo
                    ? {
                          unionId: wxUserInfo.unionId || '',
                          nickName: wxUserInfo.nickname || '',
                          picture: wxUserInfo.headImgUrl || '',
                      }
                    : null),
            }),
        };
        // 设置基础内容
        setCookie(getEnvStr(WX_USER_DATA), {
            UserId: 0,
            WxAppId: sourceInfo.weChatAppId,
            WxOpenid: wxUserInfo ? wxUserInfo.openId : openid,
            ...(wxUserInfo
                ? {
                      UnionId: wxUserInfo.unionId || '',
                      Nickname: wxUserInfo.nickname || '',
                      PictureUrl: wxUserInfo.headImgUrl || '',
                  }
                : null),
        });
        let data = await LoginByOtherType(json2formdata(params));
        let { status, result, info } = data;
        // 登录成功
        if (!status) {
            // userDate 存在
            if (result && result.UserData) {
                let currentWxUserInfo = getCurrentWeappWxData(result.UserData, sourceInfo.weChatAppId);
                !!currentWxUserInfo && setCookie(getEnvStr(WX_USER_DATA), currentWxUserInfo);
                sensorsLogin(result.UserData.StudentId);
            }

            setUserLoginInfo(data);

            resolve(data);
        } else {
            reject(info);
        }
    });

/**
 * 海外手机号通过验证码登录
 * @param phone 手机号
 * @param areaCode 手机号
 * @param code 验证码
 * @param name 姓名
 */
export const overseasLoginByVerify = (phone: string, areaCode: string, code: string, name = '') =>
    new Promise(async (resolve, reject) => {
        let GDSSID = await getGDSSID();
        let PHPSESSID = getCookie('PHPSESSID') || GDSSID;
        let sourceInfo = await getSourceInfo();
        let params = {
            loginType: 'OVERSEAS_PHONE_LOGIN',
            appid: APPID,
            GDSSID,
            source: 1,
            PHPSESSIONID: PHPSESSID,
            extendInfo: JSON.stringify({
                overseasPhone: phone.replace(/\s/g, ''),
                code: code.replace(/\s/g, ''),
                areaCode,
                ucNickName: name,
                channel: sourceInfo['channelId'] || '',
                project: sourceInfo['projectId'] || '',
            }),
        };
        let data = await LoginByOtherType(json2formdata(params));
        let { status, result, info } = data;
        // 登录成功
        if (!status) {
            setUserLoginInfo(data);
            sensorsLogin(result.UserData ? result.UserData.StudentId : '');
            checkWxStatus();
            resolve(data);
        } else {
            reject(info);
        }
    });

/**
 * 通过验证码登录并绑定公众号
 * @param phone 手机号
 * @param areaCode 手机号
 * @param code 验证码
 * @param name 姓名
 */
export const loginByVerifyAndBind = (phone: string, areaCode: string, code: string, name = '') =>
    new Promise(async (resolve, reject) => {
        let GDSSID = await getGDSSID();
        let PHPSESSID = getCookie('PHPSESSID') || GDSSID;
        let wxUserInfo = getCookie(getEnvStr(WX_USER_DATA)) || {};
        let sourceInfo = await getSourceInfo();
        let params = {
            code: code.replace(/\s/g, ''),
            appid: APPID,
            GDSSID,
            source: '1',
            PHPSESSIONID: PHPSESSID,
            phone: phone.replace(/\s/g, ''),
            areaCode: areaCode,
            ucNickName: name,
            openId: wxUserInfo.WxOpenid,
            wxAppId: wxUserInfo.WxAppId,
            unionId: wxUserInfo.UnionId || '',
            weChatNickName: wxUserInfo.Nickname || '',
            weChatPictureUrl: wxUserInfo.PictureUrl || '',
            userId: 0,
            channel: sourceInfo['channelId'] || '',
            project: sourceInfo['projectId'] || '',
        };
        let data = await loginAndBind(json2formdata(params));
        let { status, result, info } = data;

        // 重复登录
        if (status === 1610614632) {
            resolve({});
            return;
        }
        // 登录成功
        if (!status) {
            // userDate 存在
            if (result && result.UserData) {
                let currentWxUserInfo = getCurrentWeappWxData(result.UserData, sourceInfo.weChatAppId);
                !!currentWxUserInfo && setCookie(getEnvStr(WX_USER_DATA), currentWxUserInfo);
                sensorsLogin(result.UserData.StudentId);
            }
            setUserLoginInfo(data);
            checkWxStatus();
            resolve(data);
        } else {
            reject(info);
        }
    });

/**
 * 区分不同环境调用不同的登录接口
 */
export const diffEnvLoginByVerify = (phone: string, area: string, verify: string, name?: string) => {
    // 获取微信授权信息
    let wxUserInfo = getCookie(getEnvStr(WX_USER_DATA));
    // 微信环境 && 已授权
    if (window['browser'].iswx && !!wxUserInfo) {
        return loginByVerifyAndBind(phone, area, verify, name);
    } else {
        return !area ? loginByVerify(phone, verify, name) : overseasLoginByVerify(phone, area, verify, name);
    }
};

/**
 * 账号绑定手机号
 * @param phone 手机号
 * @param area 区号
 * @param verify 验证码
 */
export const bindPhoneByVerify = (phone: string, area: string, verify: string): Promise<string> =>
    new Promise((resolve, reject) => {
        let params = {
            phone: phone,
            code: verify,
            areaCode: area,
        };
        bindPhone(qs.stringify(params)).then((data: any) => {
            if (!data.status) {
                let userinfo = LStorage.get(USER_INFO);
                if (userinfo) {
                    let userinfoObj = JSON.parse(userinfo);
                    userinfoObj['Phone'] = phone;
                    LStorage.set(USER_INFO, userinfoObj);
                    checkWxStatus();
                }
                resolve('绑定成功');
            } else {
                reject(data.info || '绑定失败，请稍后再试');
            }
        });
    });

/**
 * 用户信息编辑
 * @param params 用户信息
 */
export const userInfoEdit = (params): Promise<string> =>
    new Promise((resolve, reject) => {
        updataUserInfo(qs.stringify(params)).then(async (data: any) => {
            if (!data.status) {
                let { userInfo } = await getUserLoginInfo();
                userInfo.Sex = params.sex;
                userInfo.Birthday = params.birthday;
                userInfo.NickName = params.nickname;
                userInfo.PictureUrl = params.picture;
                LStorage.set(USER_INFO, userInfo);
                resolve('修改成功');
            } else {
                reject(data.info || '修改失败，请稍后再试');
            }
        });
    });

/**
 * 解除用户与公众号的绑定关系
 */
export const unbindWeixin = () =>
    new Promise(async (resolve, reject) => {
        let { userInfo, wxUserInfo } = await getUserLoginInfo();
        let params = {
            user_id: userInfo.StudentId,
            app_id: wxUserInfo.WxAppId,
        };
        unbindingwx(qs.stringify(params)).then((data: any) => {
            if (!data.status) {
                let wxUserInfo = getCookie(getEnvStr(WX_USER_DATA));
                wxUserInfo['UserId'] = 0;
                setCookie(getEnvStr(WX_USER_DATA), wxUserInfo);
                resolve('解绑成功');
            } else {
                reject(data.info);
            }
        });
    });

/**
 * 退出登录
 * @param clearAutherize 是否清理微信授权信息 default: true
 */
export const logout = (clearAutherize = true) => {
    delCookie(getEnvStr(ACCESS_TOKEN));
    delCookie(getEnvStr(REFRESH_TOKEN));
    let activity_stock_count = LStorage.get(ACTIVITY_STOCK_COUNT);
    let activity_count_expires = LStorage.get(ACTIVITY_COUNT_EXPIRES);
    LStorage.clear();
    if (!!activity_stock_count) LStorage.set(ACTIVITY_STOCK_COUNT, activity_stock_count);
    if (!!activity_count_expires) LStorage.set(ACTIVITY_COUNT_EXPIRES, activity_count_expires);

    if (window['browser'].iswx) {
        if (clearAutherize) {
            delCookie(getEnvStr(WX_USER_DATA));
        } else {
            let wxUserInfo = getCookie(getEnvStr(WX_USER_DATA));
            wxUserInfo['UserId'] = 0;
            setCookie(getEnvStr(WX_USER_DATA), wxUserInfo);
        }
    }
};

/**
 * 检查当前登录状态
 */
export const checkLoginStatus = (localToken): Promise<string> =>
    new Promise((resolve, reject) => {
        // 有token
        if (localToken) {
            // 检查token是否有效
            checkToken(localToken)
                .then(token => {
                    resolve(token);
                })
                .catch(e => {
                    reject(e);
                });
        } else {
            reject('未登录');
        }
    });

/**
 * 检查个人登录后是企业微信还是个人微信
 */
export const checkWxStatus = (): Promise<string> =>
    new Promise(async (resolve, reject) => {
        let { result, status } = await switchWxStatus();
        LStorage.set(WX_TYPE, !!result ? '1' : '0');
        // unbindingwx(qs.stringify(params)).then((data: any) => {
        //     if(!data.status) {
        //         let wxUserInfo = getCookie(getEnvStr(WX_USER_DATA));
        //         wxUserInfo["UserId"] = 0;
        //         setCookie(getEnvStr(WX_USER_DATA), wxUserInfo);
        //         resolve('解绑成功')
        //     }else {
        //         reject(data.info)
        //     }
        // })
    });
